@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';

.CreateAndUpdateProvider_root {
  height: 100%;
}

.CreateAndUpdateProvider_wrapper {
  text-align: left;
  display: grid;  
  row-gap: 20px;
  align-items: flex-start;

  @media only screen and (min-width: $tablet-width) {
    grid-template-columns: 1fr 238px;
    column-gap: 20px;
  }

  .v-input__slot {
    margin: 0 0 4px;
  }
}

.CreateAndUpdateProvider_Form {
  max-width: 900px;
}

.CreateAndUpdateProvider_FormBody {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;

  @media only screen and (min-width: $tablet-width) {
    column-gap: 20px;
  }
}

.CreateAndUpdateProvider_title {
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0 0 14px;

  @media only screen and (min-width: $tablet-width) {
     font-size: 18px;
     line-height: 21px;
  }
}

.CreateAndUpdateProvider_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0;
}

.CreateAndUpdateProvider_select {
  input {
    font-size: 12px;
    line-height: 14px;

    @media only screen and (min-width: $tablet-width) {
      font-size: 14px;
      line-height: 16px;
    }
  }

  label {
    font-size: 12px;
    line-height: 14px;

    @media only screen and (min-width: $tablet-width) {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.CreateAndUpdateProvider_checkbox-label {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-right: 50px;
}

.CreateAndUpdateProvider_septum {  
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  border-bottom: 1px solid rgba(#2af3f3, 0.1);
  padding-bottom: 9px;

  @media only screen and (min-width: $tablet-width) {
    font-size: 14px;
    line-height: 16px;
  }
}

.CreateAndUpdateProvider_add-btn {
  margin: 16px 0;
}

.CreateAndUpdateProvider_currency-forms {
  margin-top: 24px;

  @media only screen and (min-width: $desktop-width) {
    padding: 0 24px;
  }
}

.CreateAndUpdateProvider_footer-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .CreateAndUpdateProvider_footer-btn {
    flex: 0 1 calc(33.33% - 5px);
  }

  .Button_button_Icon {
    margin: 0 5px 0 0;
  }
}

.CreateAndUpdateProvider_OperationType {
  margin: 0 0 24px;

  &Title {
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFF;
    margin: 0 0 14px;
  }

  &Items {
    display: flex;
    column-gap: 30px;
  }

  &Item {
    display: flex;
    cursor: pointer;

    &Title {
      font-size: 16px;
      line-height: 19px;
      color: #FFF;
    }

    input{
      display: none;
    }

    .InputRadioCirle{
      margin: 0 10px 0 0;
    }
  }
}